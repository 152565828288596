import ReactGA from "react-ga4";

const initializeAnalytics = () => {
  ReactGA.initialize("G-088XQN0W73"); // ska ersättas med .env senare
};

const logPageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};

const logEvent = (category: string, action: string) => {
  ReactGA.event({ category, action });
};

export { initializeAnalytics, logPageView, logEvent };
